import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import proone from '../images/proone.jpg'
import protwo from '../images/protwo.jpg'
import Pro3 from '../images/prothree.jpg'
import Pro4 from '../images/profour.jpg'
import Video from '../images/proVideo.jpg'
import Video2 from '../images/pro2Video.jpg'
import Video3 from '../images/pro3Video.jpg'

export default () => (
  <div id="app" className="workpage">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh"></meta>
          <title>Progressive Heating and Air | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/progressive-heating/" />
        </Helmet>
      <PreHeader/>
      <Header/>
        <div className="company_heading">
          <span className="company_heading--name">PROGRESSIVE + WRALDS</span>
          <h1 className="company_heading--tagline">
            Making the most memorable plumbing,
            heating, and air business in the region.
          </h1>
        </div>
        <img className="company_gallery--image" src = {Pro4} alt = "progressive water, heating, and air"/>
        <div className="company_grid">
          <div className="company_overview">
            <div className="company_overview--heading">OVERVIEW</div>
            <p className="company_overview--text">
            Progressive Service Company, a family-owned and operated plumbing, heating and air,
            and electrical company wanted to up its cool factor. Through a full-service integrated
            marketing campaign we drove brand awareness through:
            </p>
            <ul className="company_overview--list">
              <li>Creative strategy</li>
              <li>Website design and development</li>
              <li>Social media strategy and implementation</li>
              <li>Commercial video and audio production</li>
              <li>Copywriting</li>
              <li>Display ad creation</li>
              <li>Owned and operated media strategy and implementation</li>
              <li>SEO</li>
            </ul>
          </div>
          <img className="company_logo" src = {protwo} alt ="progressive service company logo"/>

          <div className="company_gallery">
            <img className="company_gallery--image three" src = {Pro3} alt ="progressive service company logo"/>
            <img className="company_gallery--image one" src = {proone} alt ="progressive service company logo"/>
            <a href="https://vimeo.com/374683591" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video} alt ="Progressive Heating Company video"/>
            </a>
            <a href="https://vimeo.com/377149070" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video2} alt ="Progressive Heating Company video"/>
            </a>
            <a href="https://vimeo.com/374164545" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video3} alt ="Progressive Heating Company video"/>
            </a>
          </div>
        </div>
    <Footer/>
  </div>
)
